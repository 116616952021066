import moment from 'moment';

export default (data, type) => {
  switch(type) {
    case 'date':
      if (data) return moment(data).format('DD MMM YYYY')
      return ''
    default:
      return null
  }
}