
export default {
  'development': {
    BASE_URL: "https://front-staging.sleek.sg",
    SLEEK_URL: "https://admin-sit.sleek.sg",
  },
  'staging': {
    BASE_URL: "https://front-staging.sleek.sg",
    SLEEK_URL: "https://admin-staging.sleek.sg",
  },
  'production': {
    BASE_URL: "https://front.sleek.sg",
    SLEEK_URL: "https://admin.sleek.sg",
  },
}
