import React from "react";
import { UncontrolledCollapse, Button } from "reactstrap";

import "./style.css";
import { MAILROOM } from "../../../static/apiUrl";
import format from "../../../utils/formatter";
import TableRow from "../table-row/index";

const Table = props => {
  const { companyDetails, toggleID } = props;
  return (
    <div className="table company">
      <div color="info" className="companyName" id={`company${toggleID}`}>
        <span id="companyName">{companyDetails.companyName}</span>
        <i className="fa fa-chevron-down"></i>
      </div>
      <UncontrolledCollapse toggler={`company${toggleID}`} defaultOpen={toggleID === 0}>
        <TableRow title="Director/s" data={companyDetails.directors} key={companyDetails.companyID+"1"}/>
        <TableRow
          title="Individual Shareholder/s"
          data={companyDetails.individualShareholders}
          key={companyDetails.companyID+"2"}
        />
        <TableRow
          title="Corporate Shareholder/s"
          data={companyDetails.corporateShareholders}
          key={companyDetails.companyID+"3"}
        />
        <TableRow
          title="FYE"
          data={format(companyDetails.financial_year, "date")}
          key={companyDetails.companyID+"4"}
        />
        <TableRow
          title="Stakeholders from Sleek Team"
          data={companyDetails.sleekStakeholders}
          key={companyDetails.companyID+"5"}
        />
        <TableRow title="Status" data={companyDetails.status}
        key={companyDetails.companyID+"6"} />
        <TableRow
          title="Services' Information"
          data={companyDetails.company_services}
          key={companyDetails.companyID+"7"}
        />
        <div className="table-row">
          <div id="table-data">
            <span>Mailroom</span>
          </div>
          <div id="table-data">
            <a rel="noopener noreferrr" target="_blank" href={MAILROOM}>
              Mailroom
            </a>
          </div>
        </div>
      </UncontrolledCollapse>
    </div>
  );
};

export default Table;
