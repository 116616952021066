import React from 'react';
import CompanyDetails from './views/company-details'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <CompanyDetails />
      </header>
    </div>
  );
}

export default App;
