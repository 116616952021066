import React from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import queryString from'query-string';
import "./style.css";

import Table from "../../components/common/table/";

import {
  GET_COMPANY_DETAILS,
  POST_TOKEN
} from "../../static/apiUrl";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class CompanyDetails extends React.Component {
  state = {
    companies: [{
      companyID: "",
      companyName: "",
      directors: [],
      individualShareholders: [],
      corporateShareholders: [],
      financial_year: "",
      sleekStakeholders: [],
      status: "",
      company_services: [],
    }],
    errorMessage: "Please click an email",
    loading: false,
    sendersEmail: "",
    assignConvoMessage: "",
  };

  constructor() {
    super();
    const { Front } = window;
    Front.on("conversation", data => {
      // triggered when a conversation is loaded
      this.setState({
        loading: true,
        sendersEmail: data.contact.handle ? data.contact.handle : ""
      });

      // get company ID based on the sender's email
      this.getCompanyDetails(data.contact.handle).then(async result => {
        if (!result.Success) {
          this.setState({
            errorMessage: `Error Message: ${result.Message}
            Getting Company Details Failed`,
            loading: false
          })
          return;
        } else if (result.Success && !result.Data) {
          this.setState({
            errorMessage: result.Message,
            loading: false
          })
          return;
        }
        const companies = await result.Data.reduce((prev, current) => {
          let individualShareholders = [];
          let corporateShareholders = [];

          const { CompanyDetails, PIC, Shareholders, CompanyServices } = current;
          const { name: companyName, financial_year, status, ID } = CompanyDetails;
          
          const company_services = this.getCompanyServices(CompanyServices);

          // get all pic
          const sleekStakeholders = PIC.reduce((initial, current) => {
            if (current.user._id) {
              initial.push(`${current.title} : ${current.user.first_name} ${current.user.last_name}`);
            }
            return initial
          }, []);

          // get director ,corporate and individual shareholders 
          const directors = Shareholders.reduce((arrDirector, u) => {
            const user = u.user.first_name + " " + u.user.last_name;

            // if user is a stakeholder
            if (u.shareholder) {
              // check if stakeholder is corporate or individual
              u.shareholder.corporate ? corporateShareholders.push(`${u.shareholder.corporate.name}: ${user}`) : individualShareholders.push(user);
            }

            // if user is a director
            if (u.director) {
              arrDirector.push(user);
            }
            return arrDirector
          }, []);

          prev.push({
            companyID: ID,
            companyName,
            financial_year,
            status: status.replace(/_/g, " "),
            company_services,
            directors,
            individualShareholders,
            corporateShareholders,
            sleekStakeholders,
          })
          return prev
        }, [])

        await this.setState({
          companies,
          loading: false,
          errorMessage: ""
        })
        
      })
      .catch(err => {
        console.log(err)
      });

    });
  }

  componentDidMount = () => {
    this.getToken()
  };

  getToken = () => {
    const data = {
      auth_secret: queryString.parse(window.location.search).auth_secret
    }

    return axios.post(POST_TOKEN, data)
      .then(({data}) => {
        if (data.Success) {
          localStorage.setItem("auth_secret", data.Data.Token)
        } else {
          this.setState({
            errorMessage: data.Message
          })
        }
      })
      .catch(console.log)
  }

  getCompanyDetails = email => {
    const auth_secret = localStorage.getItem("auth_secret");
    if (!auth_secret) {
      return {
        Success: false,
        Message: "Auth Secret is empty"
      }
    }

    const header = {
      headers: {
        Authorization: auth_secret
      }
    }

    return new Promise((resolve, reject) => {
      axios 
        .get(GET_COMPANY_DETAILS + email, header)
        .then(result => resolve(result.data))
        .catch(err => {
          this.setState({
            errorMessage: `Error Message :   ${err.message} 
            Endpoint: ${err.config.method} ${err.config.url}
            "Getting company details by email: getCompanyDetails"`,
            loading: false
          });
          reject(err);
        });
    });
  };

  getCompanyServices = services => {
    return services ? services.map(service => service.charAt(0).toUpperCase() + service.slice(1)) : []
  };

  render() {
    const {
      companies,
      errorMessage,
      loading,
      sendersEmail,
    } = this.state;
    return (
      <div style={{ margin: 20 }}>
        {loading ? (
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={10000} //10 secs
          />
        ) : errorMessage ? (
          <h5>{errorMessage}</h5>
        ) : (
              <div>
                {" "}
                <div className="table-row senderEmail">
                  <div id="table-data">
                    <span>Sender's Email</span>
                  </div>
                  <div id="table-data">
                    <span>{sendersEmail} </span>
                  </div>
                </div>
                
                {companies.map((company, i) => (
                  // company.companyName ? <Table  companyDetails={company} toggleID={i}/> : <></>
                  <Table  companyDetails={company} toggleID={i} key={1+i}/>
                ))}
          </div>
        )}
      </div>
    );
  }
}

export default CompanyDetails;
