import React from "react";
import "./style.css";

const TableRow = props => {
  const { title, data } = props;
  return (
    <div className="table-row">
      <div className="table-data">
        <span>{title}</span>
      </div>
      <div className="table-data">
        {typeof data === "object" && data.length ? ( // for arrays
          <>
            {data.map((d, i) => (
              <>
                <span key={i}>{d}</span>
                <br />
              </>
            ))}
          </>
        ) : (
          <span>{data.length ? data : "-"}</span>
        ) // string and empty
        }
      </div>
    </div>
  );
};

export default TableRow;
